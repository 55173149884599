/*>>>>>>>> Service area styling (landing-8)<<<<<<<<<*/

.08__service-section{
  padding-top: 40px;
  padding-bottom: 50px;
  @include tablet{
    padding-top: 100px;
    padding-bottom: 150px;
  }
}
.service-card--1{
  border-radius: 10px;
  border: 1px solid #2d2d30;
  background-color: $l8-primary-heading;
  transition: .4s;
  min-height:89px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 28px;
  padding-right: 55px;
  display: flex;
  align-items: center;
  position: relative;
  transform: translateX(0px);
  will-change: transfrom;
  &:hover{
    box-shadow: 0 52px 54px rgba(25, 25, 27, 0.3);
    border-radius: 10px;
    background-color: $l8-secondary-color;
    i{
      transform: translateX(10px);
      opacity: 1;
    }
  }
  .card-title{
    color: #ffffff;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    margin-bottom: 0;
  }
  i{
    font-size: 30px;
    color: #fff;
    position: absolute;
    right: 0;
    margin-right: 30px;
    opacity: 0;
    transition: .4s;
  }
}
/*********** CSS TABLE OF CONTENTS *******************

|--- 0.NerdNow Base Styles
|---------* Spacings
|---------* Common
|
|--- 1.NerdNow Components Styles
|---------* Banner
|---------* Buttons
|---------* Footers
|---------* Headers
|---------* Menu
|
|--- 2.NerdNow Pages Individual Styles
|---------* Contact
|---------* Career_Details
|---------* Career_Page
|---------* Case_Study
|---------* Pricing_Pages
|---------* Blog_Pages
|---------* Contact_Pages
|---------* Thank_You
|---------* 404
|---------* Terms_page
|---------* Faq_page
|---------* coming_soon_page
|
|--- 3.NerdNow section Base Styles
|---------* Hero_Area_Styles
|---------* Brand_Area_Styles 
|---------* Feature_Area_Styles
|---------* Content_Area_Styles
|---------* Testimonail_Area_Styles
|---------* CTA_Area_Styles
|---------* Author_Area_Styles
|---------* Pricing_Area_Styles
|---------* Review_Area_Styles
|---------* Service_Area_Styles
|---------* Case_Area_Styles 
|---------* Fact_Area_Styles 
|---------* Contact_Area_Styles
|---------* Offer_Area_Styles
|---------* Video_Area_Styles 
|---------* Team_Area_Styles 

******************************************************/


@import 'helpers/variables';
@import 'helpers/mixins';
/************************************* 
    00.NerdNow Base Styles
*************************************/
@import 'base/spacings';
@import 'base/common';

/************************************* 
    01.NerdNow Components Styles
*************************************/

/*~~~~ Banner(Inner_Pages)
~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'components/banner';

/*~~~~ Buttons
~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'components/buttons';

/*~~~~ Footers
~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'components/footer';

/*~~~~ Headers
~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'components/header';

/*~~~~ Menu
~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
@import 'components/menu';


/************************************* 
    02.NerdNow Pages Individual Styles
*************************************/ 

/*==== Contact -------*/ 
@import 'pages/contact';

/*==== Career_Details -------*/ 
@import 'pages/careerdetails';

/*==== Career_Page -------*/ 
@import 'pages/career-page';

/*==== Case_Study -------*/ 
@import 'pages/case-study';

/*==== Pricing_Pages -------*/ 
@import 'pages/pricing-pages';

/*==== BLog_Pages -------*/ 
@import 'pages/blog-pages';

/*==== Contact_Pages -------*/ 
@import 'pages/contact-pages';

/*==== Thank_You -------*/ 
@import 'pages/thank-you';

/*==== 404 -------*/ 
@import 'pages/404';

/*==== Terms_page -------*/ 
@import 'pages/terms-page';

/*==== Faq_page -------*/ 
@import 'pages/faq';

/*==== coming_soon_page -------*/ 
@import 'pages/coming-soon';

/*==== Footer Styles -------*/ 

/************************************* 
    NerdNow section Base Styles
*************************************/

/* --------> Hero_Area_Styles 
--------------------------------------*/
@import 'sections/hero-area';

/* --------> Brand_Area_Styles 
--------------------------------------*/
@import 'sections/brands-area';

/* --------> Feature_Area_Styles 
--------------------------------------*/
@import 'sections/feature-area';

/* --------> Content_Area_Styles 
--------------------------------------*/
@import 'sections/content-area';

/* --------> Testimonail_Area_Styles 
--------------------------------------*/
@import 'sections/testimonial-area';

/* --------> CTA_Area_Styles 
--------------------------------------*/
@import 'sections/cta-area';

/* --------> Author_Area_Styles 
--------------------------------------*/
@import 'sections/author-area';

/* --------> Pricing_Area_Styles 
--------------------------------------*/
@import 'sections/pricing-area';

/* --------> Review_Area_Styles 
--------------------------------------*/
@import 'sections/review-area';

/* --------> Service_Area_Styles 
--------------------------------------*/
@import 'sections/service-area';

/* --------> Case_Area_Styles 
--------------------------------------*/
@import 'sections/case-area';

/* --------> Fact_Area_Styles 
--------------------------------------*/
@import 'sections/fact-area';

/* --------> Contact_Area_Styles 
--------------------------------------*/
@import 'sections/contact-area';

/* --------> Offer_Area_Styles 
--------------------------------------*/
@import 'sections/offer-area';

/* --------> Video_Area_Styles 
--------------------------------------*/
@import 'sections/video-area';

/* --------> Team_Area_Styles 
--------------------------------------*/
@import 'sections/team-area';

@import 'dark-version';
.contact-page{
  .section-title{
    margin-bottom: 50px;
    @include desktops{
      margin-bottom: 80px;
    }
    h2{
      color: #19191b;
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.88px;
      line-height: 70px;
    }
  }
}


.nerdnow-contact-form{
  .form-group{
    margin-bottom: 30px;
    .form-control{
      margin-bottom: 0;
    }
    label{
      color: #19191b;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.56px;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  .form-title{
    color: #19191b;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;

  }
  .form-control{
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    color: #9c9aaa;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    margin-bottom: 17px;
    text-indent: 25px;
  }
  input{
    min-height: 73px;
  }
  textarea{
    min-height: 154px;
    max-height: 154px;
    padding-top: 20px;
  }
  .btn{
    width: 100%;
    max-height: 60px;
    min-height: 60px;
    &.w-small{
      max-width: 255px;
    }
  }
}

.contact-widget-wrapper{
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @include desktops{
    padding-left: 140px;
    padding-right: 150px;
  }
  @include large-desktops{
    padding-left: 150px;
    padding-right: 150px;
  }
}
.contact-widget-block{
.title{
  color: #19191b;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.75px;
  line-height: 1
}
p{
  color: #696871;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 39px;
  margin-bottom:0;
}
}
.contact-form-title--big{
  .form-title{
    color: #19191b;
    font-weight: 700;
    letter-spacing: -2.5px;
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 15px;
    @include tablet{
      font-size: 80px;
      line-height: 84px;
      margin-bottom: 30px;
    }
  }
  p{
    color: #696871;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 39px;
  }
}

.contact-map {
  
  width: 100%;
  height: 500px;
  margin-bottom: 50px;
  @include desktops{
    margin-top: 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 47%;
  }
}

/*>>>>>>>> Author area styling (landing-6)<<<<<<<<<*/
  .06__author-section{
    border-bottom:1px solid #eae9f2;
    padding-top: 17px;
    padding-bottom: 45px;
    background: #f6f6f8;
    @include tablet{
      padding-top: 90px;
      padding-bottom: 85px;
    }
  }
  .06__author-widget{
    display: flex;
    flex-direction: column;
    @include mobile-lg{
      flex-direction: row;
    }
    @include desktops{
      padding-left: 90px;
      padding-right: 90px;
    }
    .widget-image{
      min-width: 111px;
      max-width: 111px;
      height: 111px;
      border-radius: 500px;
      overflow: hidden;
    }
    .widget-text{
      padding-top: 20px;
      @include mobile-lg{
        padding-left: 30px;
        padding-top: 0px;
      }
      .author-name {
        color: $l6-primary-heading;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        line-height: 28px;
      }
      p {
        color: $l6-primary-heading;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
        font-style: italic;
        margin-bottom: 13px;
      }
    }
  }


    /*>>>>>>>> About Author section styling (landing-6)<<<<<<<<<*/
    .06__about-author-section {
      padding-top: 40px;
      padding-bottom: 35px;
      background: #f6f6f8;
      @include tablet{
        padding-top: 90px;
        padding-bottom: 85px;
      }
      .section-title {
        margin-bottom: 40px;
        @include tablet{
          margin-bottom: 80px;
        }
        h2 {
          letter-spacing: -1.88px;
        }
      }
    }
    .06__author-image{
      min-width: 271px;
      max-width: 271px;
      min-height: 271px;
      max-height: 271px;
      border-radius: 500px;
      overflow: hidden;
      margin-bottom: 30px;
      @include desktops{
        margin-bottom: 0px;
      }
    }
    .06__author-text{
      margin-top: 30px;
      @include tablet{
        margin-top: 0px;
      }
      .title{
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.75px;
        color: $l6-primary-heading;
        margin-bottom: 30px;
      }
      p{
        color: $l6-primary-text;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 28px;
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0px;
        }
        .link{
          font-size: 16px;
          font-weight: 300;
          color: $l6-primary-heading;
          text-decoration: underline;
        }
      }
    }
  
  
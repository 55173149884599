  /*>>>>>>>> Testimonial area Common styling <<<<<<<<<*/



  /*~~~~Testimonial Area styling 1~~~~~*/ 

  .01_{
    &_testimonial-section {
      .section-title {
        margin-bottom: 60px;
        @include desktops {
          margin-bottom: 100px;
        }
      }
    }
  }
  .testimonial-card--1 {
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    padding: 25px 25px 16px;
    .card-top-area {
      display: flex;
      margin-bottom: 5px;
      .customer-img {
        flex-basis: 16%;
        margin-right: 6px;
      }
      .customer-identity {
        flex-basis: 68%;
        .name {
          color: $l1-primary-heading;
          font-family: $l1-primary-font;
          font-size: 21px;
          font-weight: 700;
          letter-spacing: -0.66px;
          line-height: 28px;
        }
        .company {
          color: $l1-primary-text;
          font-family: $l1-primary-font;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: -0.5px;
          line-height: 28px;
        }
      }
      .review-media {
        flex-basis: 16%;
        text-align: right;
        i {
          // width: 27px;
          // height: 22px;
          font-size: 27px;
          color: $l1-secondary-color;
        }
      }
    }
    .review-text {
      color: $l1-primary-heading;
      font-family: $l1-primary-font;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      .secondary-text {
        color: $l1-secondary-color;
        font-family: $l1-primary-font;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
      }
    }
  }
  .testimonial-link{
    color: $l1-secondary-color;
    font-family: $l1-primary-font;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    display: inline-flex;
    align-items: center;
    i{
      margin-right: 15px;
    }
    span{
      font-size: inherit!important;
    }
  }


  /*~~~~Testimonial Area styling 2~~~~~*/ 

  .02_ {
    &_testimonial-section {
      background-color: #f7f7fb;
    }
  }
  .testimonial-slider {
    .slick-arrow {
      display: none!important;
    }
    .slick-dots {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        font-size: 0;
        width: 17px;
        height: 8px;
        border-radius: 4px;
        background-color: #eae9f2;
        margin-left: 5px;
        margin-right: 5px;
        transition: .5s;
        &.slick-active {
          width: 45px;
          height: 8px;
          border-radius: 4px;
          background: $theme-blue-color;
        }
      }
    }
  }
  .testimonial-card--2 {
    max-width: 468px;
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    padding: 25px 28px;
    margin: 0 auto;
    .card-footer {
      display: flex;
      background: transparent;
      border-top: none;
      padding: 0;
      margin-top: 28px;
      .customer-img {
        // flex-basis: auto;
        margin-right: 12px;
        border-radius: 500px;
        overflow: hidden;
        min-width: 41px;
        max-width: 41px;
        min-height: 41px;
        max-height: 41px;
        margin-top: 2px;
      }
      .customer-identity {
        // flex-basis: 68%;
        .name {
          color: $l1-primary-heading;
          font-family: $l1-primary-font;
          font-size: 21px;
          font-weight: 700;
          letter-spacing: -0.66px;
          line-height: 28px;
        }
        .company {
          color: $l1-primary-text;
          font-family: $l1-primary-font;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: -0.5px;
          line-height: 28px;
        }
        :last-child {
          margin-bottom: 0;
        }
      }

    }
    .review-text {
      color: $l1-primary-heading;
      font-family: $l1-primary-font;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
    }
  }

  /*~~~~Testimonial Area styling 3~~~~~*/ 

  .testimonial-slider {
    .slick-arrow {
      display: none!important;
    }
    .slick-list{
      @include tablet{
        margin-left: -15px;
        margin-right: -15px;
      }
      @include desktops{
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-slider{
      @include tablet{
        margin-left: 15px;
        margin-right: 15px;
      }
      @include desktops{
        margin-left: 0;
        margin-right: 0;
      }
    }
    .slick-dots {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        font-size: 0;
        width: 17px;
        height: 8px;
        border-radius: 4px;
        background-color: #eae9f2;
        margin-left: 5px;
        margin-right: 5px;
        transition: .5s;
        &.slick-active {
          width: 45px;
          height: 8px;
          border-radius: 4px;
          background: $l4-secondary-color;
        }
      }
    }
  }
  .testimonial-card--2 {
    max-width: 468px;
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: #ffffff;
    padding: 25px 28px;
    margin: 0 auto;
    .card-footer {
      display: flex;
      background: transparent;
      border-top: none;
      padding: 0;
      margin-top: 28px;
      .customer-img {
        // flex-basis: auto;
        min-width: 41px;
        max-width: 41px;
        min-height: 41px;
        max-height: 41px;
        margin-right: 12px;
        border-radius: 500px;
        overflow: hidden;
        margin-top: 3px;
      }
      .customer-identity {
        // flex-basis: 68%;
        .name {
          color: $l1-primary-heading;
          font-family: $l1-primary-font;
          font-size: 21px;
          font-weight: 700;
          letter-spacing: -0.66px;
          line-height: 28px;
        }
        .company {
          color: $l1-primary-text;
          font-family: $l1-primary-font;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: -0.5px;
          line-height: 28px;
        }
        :last-child {
          margin-bottom: 0;
        }
      }

    }
    .review-text {
      color: $l1-primary-heading;
      font-family: $l1-primary-font;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
    }
  }




  /*~~~~Testimonial Area styling 5 (landing-5)~~~~~*/ 

  .05_ {
    &_testimonial-section {
      background-color: #F7F7FB;
      padding-bottom: 60px;
      padding-top: 20px;
      @include tablet{
        padding-top: 120px;
      }
    }
  }
  .testimonial-slider-l5{
    .single-slide{
      @include desktops{
        margin: 60px 60px 100px 60px;
      }
    }
   .slick-arrow {
      position: absolute;
      top: 50%;
      font-size: 0;
      height: 65px;
      display: flex;
      width: 65px;
      background: $l5-secondary-color;
      transition: .4s;
      border-radius: 500px;
      transform: translate(-50%,-50%);
      z-index: 999;
      margin-top: -20px;
      &:hover{
        background-color: #FFFFFF;
        &:after{
          color: $l5-secondary-color;
        }
      }
      &:after{
        position: absolute;
        content: "";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        transition: .4s;
      }
      &.slick-next{
        right: 0;
        left: auto;
        transform: translate(50%,-50%);
        // background: $l5-secondary-color;
        @include desktops{
          margin-right:60px;
        }
        &:after{
          // color: #fff;
          content: "\f061";
        }
      }
      &.slick-prev{
        @include desktops{
          margin-left:60px;
        }
        &:after{
          content: "\f060";
        }
      }
    }
  }
  .testimonial-card--5 {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 52px 54px rgba(65, 62, 101, 0.1);
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
    @include mobile-lg{
      padding: 35px 35px 20px 35px;
    }
    @include tablet{
      padding: 10px;
      flex-direction: row;
    }
    .testimonial-image{
      border-radius: 10px;
      overflow: hidden;
        @include mobile-lg{
          min-width: 315px;
        }
      img{
        margin: 0 auto;
        max-width: unset;
        width: 100%;
        @include mobile-lg{
          max-width: 100%;
          width: auto;
        }
      }
    }
    .testmonial-texts{
     text-align: center;
     @include tablet{
      padding-right: 30px;
     }
     .testimonial-text-inner{
       padding-top: 35px;
       margin: 0 auto;
       padding-bottom: 30px;
       @include mobile-lg{
         max-width: 435px;
        }
        @include tablet{
        max-width: 505px;
         padding-left: 30px;
        }
        @include desktops{
          padding-top: 65px;
        padding-left: 100px;
        padding-bottom: 15px;
      }
        @include desktops{
          padding-top: 65px;
        padding-left: 100px;
      }
     }
     .quote-shape{
       display: flex;
       justify-content: center;
       align-items: center;
       margin-bottom: 30px;
     }
     p{
      color: $l5-primary-heading;
      font-size: 21px;
      font-weight: 300;
      letter-spacing: -0.66px;
      line-height: 39px;
      padding-top: 10px;
     }
     .username{
      color: $l5-primary-heading;
      font-size: 21px;
      font-weight: 700;
      letter-spacing: -0.66px;
      line-height: 28px;
     }
     .rank{
      color: $l5-primary-text;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.5px;
      line-height: 28px;
     }
    }
  }


  /*~~~~Testimonial Area styling 6 (landing-6)~~~~~*/ 

  .06__testimonial-section {
    background-color: #f6f6f8;
    padding-top: 50px;
    @include tablet{
      padding-top: 80px;

    }
  }
  .testimonial-slider-l6{
    .slick-list{
      margin:0 -20px;
    }
    .single-slide{
      margin: 60px 20px 45px;
    }
   .slick-arrow {
      position: absolute;
      top: 50%;
      font-size: 0;
      height: 65px;
      display: flex;
      width: 65px;
      background: $l6-secondary-color;
      transition: .4s;
      border-radius: 500px;
      transform: translate(-50%,-50%);
      z-index: 99;
      &:hover{
        background-color: #ffffff;
        &:after{
          color: $l6-secondary-color;
        }
      }
      &:after{
        position: absolute;
        content: "";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        transition: .4s;
        margin-left: 0;
      }
      &.slick-next{
        right: 0;
        left: auto;
        transform: translate(50%,-50%);
        // background: $l6-secondary-color;
        @include desktops{
          // margin-right:60px;
        }
        &:after{
          // color: #fff;
          content: "\f061";
        }
      }
      &.slick-prev{
        @include desktops{
          // margin-left:60px;
        }
        &:after{
          content: "\f060";
        }
      }
    }
    
  }
  .testimonial-card--6 {
    display: flex;
    background: #fff;
    box-shadow: 0 12px 34px -11px rgba($theme-ash-color, 0.1);
    border-radius: 10px;
    flex-direction: column;
    padding-right:30px ;
    padding-left:30px ;
    padding-top: 50px;
    padding-bottom: 50px;
    @include mobile-lg{
      padding-top: 90px;
      padding-bottom: 90px;
    }
    @include tablet{
      flex-direction: row;
      padding-right:50px ;
      padding-left:50px ;
    }
    @include desktops{
      padding-right:100px ;
      padding-left:100px ;
    }
    @include large-desktops{
      padding-right:120px ;
      padding-left:120px ;
    }
    .testimonial-image{
      
      @include brk-point(400px){
        min-width: 215px;
      }
      img{
        margin: 0 auto;
        max-width: unset;
        width: 100%;
        @include brk-point(400px){
          max-width: 100%;
          width: auto;

        }
      }
    }
    .testimonial-number-block{
      min-width: 215px;
      height: 213px;
      border-radius: 8px;
      background-color: #eae9f2; 
      text-align: center; 
      margin: 0 auto;
      @include tablet{
        margin-left:0;      }
      span{
        color: $theme-ash-color;
        font-size: 140px;
        font-weight: 900;
        letter-spacing: -4.38px;
      }
    }
    .testmonial-texts{
      text-align: center;
      padding-top: 30px;
      @include tablet{
        text-align: left;
      padding-left: 50px;
      padding-right: 0px;
      padding-top: 0px;
     }
     @include desktops{
      padding-left: 90px;
     }
     @include large-desktops{
      padding-left: 120px;
     }
    
      .title-top{
        color: $l6-secondary-color;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 28px;
        margin-bottom: 10px;
      }
      .title-top{
        color: $l6-secondary-color;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 28px;
      }
      .testmonial-title{
        color: $l6-primary-heading;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.75px;
        padding-bottom: 30px;
      }
      p{
        color: $l6-primary-heading;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: -0.66px;
        line-height: 39px;
        &:last-child{
          margin-bottom: 0;
        }
      }
     }

  }
  /*~~~~Testimonial Area styling 8 (landing-8)~~~~~*/ 
.08__testimonial-section{
  padding-top: 80px;
  @include tablet{
    padding-top: 155px;
  }
}
.08__single-testimonial{
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  .brand-logo{
    margin-bottom: 33px;
  }
  p{
    color: #fff;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 39px;
  }
  .user-block{
    text-align: center;
    padding-top: 22px;
    margin-top: auto;
    .user-image{
      max-width: 67px;
      max-height: 67px;
      display: inline-block;
      margin-bottom: 19px;
      img{
        border-radius:500px; 
      }
    }
    .user-text{
      .user-title{
        color: #fff;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -0.66px;
        line-height: 28px;
      }
      .user-rank{
        color: $l8-primary-text;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 28px;
      }
    }
  }
}

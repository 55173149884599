

  .error-page-main{
    padding-top: 55px;
    padding-bottom: 70px;
    @include tablet{
      padding-top: 110px;
      padding-bottom: 140px;
    }
    .contents{
      text-align: center;
      .content-icon{
        width: 118px;
        height: 118px;
        background-color: rgba(240, 64, 55, 0.1);
        border-radius: 500px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 48px;
      } 
      .content-text{
        .title{
          color: #19191b;
          font-weight: 700;
          letter-spacing: -2.5px;
          margin-bottom: 12px;
          font-size: 34px;
          line-height: 46px;
          @include tablet{
            font-size: 80px;
          line-height: 84px;
          }
        }
        p{
          color: #696871;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: -0.66px;
          line-height: 39px;
        }
      }
      .content-btn{
        padding-top: 18px;
        .btn{
          min-width: 250px;
          min-height: 60px;
          border-radius: 10px;
          border: 1px solid #c31a12;
          color: #f04037;
          font-size: 21px;
          font-weight: 500;
          letter-spacing: -0.66px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding-left: 20px;
          padding-right: 20px;
          transition: .4s;
          &:hover{
            background: #c31a12;
            color: #ffff;
          }
        }
      }
    }
  }
  /*>>>>>>>> Footer area styling <<<<<<<<<*/


